import React, { useState, useContext, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { navigate } from 'gatsby';
import MainHeader from '../../components/main-header/main-header';
import PageHeader from '../../components/page-header/page-header';
import Button from '../../components/button/button';
import MainFooter from '../../components/main-footer/main-footer';
import iconCalendar from '../../images/calendar.svg';
import ExclamationIcon from '../../images/exclamation-circle.svg';
import {
  cancelEdit,
  changeValorParcela,
  isEditValid,
} from '../../helpers/editQuotation';
import Dropdown from '../../components/dropdown/dropdown';
import fieldMasks from '../../helpers/fieldMasks';
import { DataContext } from '../../data/DataProvider';
import MaskedInput from 'react-input-mask';
import moment from 'moment';
import './index.scss';
import DefaultLayout from '../../components/layouts/default-layout';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const block = 'page-informacoes-do-seguro';
const typeInsurance = [
  {
    label: 'Novo',
    value: 1,
  },
  {
    label: 'Renovação Akad',
    value: 2,
  },
  {
    label: 'Renovação Congênere',
    value: 3,
  },
];

const Page = ({ location }) => {
  const {
    quotation,
    setQuotation,
    originalQuotation,
    setModal,
    setOriginalQuotation,
    responseMotor,
  } = useContext(DataContext);
  const [helptext, setHelptext] = useState();
  const [isValid, setIsValid] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (!quotation || !Object.keys(quotation).length) navigate('/');
    if (quotation && !quotation.dadosEmpresa)
      return navigate('/dados-da-empresa');
  }, [quotation, quotation?.dadosEmpresa]);

  const cancel = () => {
    cancelEdit(setQuotation, originalQuotation);
  };

  const onSubmit = () => {
    if (!quotation?.propostaId) return navigate('/avaliacao-de-risco');
    setDisableSave(true);
    changeValorParcela(
      quotation,
      responseMotor.response,
      setModal,
      setDisableSave,
      setOriginalQuotation
    );
  };
  const intialValues = {
    inicioVigenciaApolice:
      quotation?.informacoesSeguro?.inicioVigenciaApolice &&
      moment(quotation?.informacoesSeguro?.inicioVigenciaApolice).format(
        'DD/MM/YYYY'
      ),
  };

  const regrasVigencia = (date) => {
    moment.locale('pt-br');
    const dayNow = moment(); // Data atual
    const limiteDay = moment().add(45, 'days'); // Data limite de 45 dias
    const dateEnd = moment(date, 'DD/MM/YYYY').add(1, 'years'); // Data fim da vigência de 1 ano
    const dateInit = moment(date, 'DD/MM/YYYY'); // Data fim da vigência de 365 dias

    if (moment(date, 'DD-MM-YYYY').isValid()) {
      if (moment(date, 'DD-MM-YYYY').isAfter(moment(limiteDay, 'DD-MM-YYYY'))) {
        setIsValid(false);
        setHelptext('A data não pode ser superior a 45 dias');
        trackCustomEvent({
          category: 'Data superior a 45 dias',
          action: '',
          label: dateInit.format('DD/MM/YYYY'),
        });
      } else if (
        moment(date, 'DD-MM-YYYY').isBefore(
          moment(dayNow, 'DD-MM-YYYY').subtract(1, 'day')
        )
      ) {
        setIsValid(false);
        setHelptext('A data não pode ser inferior a data atual');
        trackCustomEvent({
          category: 'Data superior a 45 dias',
          action: '',
          label: dateInit.format('DD/MM/YYYY'),
        });
      } else {
        setHelptext('');
        setQuotation({
          ...quotation,
          informacoesSeguro: {
            ...quotation.informacoesSeguro,
            inicioVigenciaApolice: moment(dateInit).format('YYYY-MM-DD'),
            fimVigenciaApolice: moment(dateEnd).format('YYYY-MM-DD'),
          },
          isProposal: false,
        });
        trackCustomEvent({
          category: 'Data selecionada',
          action: '',
          label: dateInit.format('DD/MM/YYYY'),
        });
      }
    } else {
      setIsValid(false);
      setHelptext('Digite uma data válida');
      trackCustomEvent({
        category: 'Data inválida',
        action: '',
        label: dateInit,
      });
    }
  };

  const tipoSeguroChangeHandler = useCallback(
    (selectedItem) => {
      trackCustomEvent({
        category: 'Tipo de seguro',
        action: 'Click',
        label: selectedItem.value,
      });
      setQuotation({
        ...quotation,
        informacoesSeguro: {
          ...quotation.informacoesSeguro,
          tipoDeSeguro: selectedItem.value,
        },
        isProposal: false,
      });
    },
    [quotation, setQuotation]
  );

  useEffect(() => {
    if (
      quotation &&
      quotation.informacoesSeguro &&
      quotation.informacoesSeguro.tipoDeSeguro &&
      quotation.informacoesSeguro.inicioVigenciaApolice &&
      quotation.informacoesSeguro.fimVigenciaApolice
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [quotation]);

  return (
    <DefaultLayout currentPagePath={location.pathname}>
      <main className={block}>
        <MainHeader />
        <PageHeader
          backButtonHandler={() => navigate('/dados-da-empresa')}
          editMode={!!quotation?.propostaId}
          title="Informações do seguro"
          description={
            <span>
              O início da vigência não pode ser retroativo a data de envio do
              pedido de emissão e ser for vigência
              <br />
              futura não pode ultrapassar a 45 dias da data da cotação. A
              vigência da apólice é de 365 dias.
            </span>
          }
          breadCrumbs={[
            {
              label: 'Início',
              path: '/',
            },
            {
              label: 'CNPJ',
              path: '/cnpj',
            },
            {
              label: 'Dados da empresa',
              path: '/dados-da-empresa',
            },
            {
              label: 'Informações do seguro',
              path: '/informacoes-do-seguro',
            },
          ]}
          fixed
          hide={quotation?.personalizado}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={`container--large ${block}--fixed`}>
            <div className={`${block}--row`}>
              <div className={`${block}--item`}>
                <fieldset className="fieldset">
                  <span className={`${block}__label`}>Tipo de seguro</span>
                  <Dropdown
                    className={`${block}__dropdown`}
                    placeholder="Selecione"
                    items={typeInsurance}
                    changeHandler={tipoSeguroChangeHandler}
                    selectedValue={quotation?.informacoesSeguro?.tipoDeSeguro}
                  />
                </fieldset>
              </div>
              <div className={`${block}--item`}>
                <fieldset className="fieldset">
                  <label
                    className="label large"
                    htmlFor="inicioVigenciaApolice"
                  >
                    Início da vigência
                  </label>
                  <img
                    alt="Data"
                    src={iconCalendar}
                    className={`${block}--iconInput`}
                  />
                  <MaskedInput
                    defaultValue={intialValues.inicioVigenciaApolice}
                    placeholder="Informe a data de início"
                    {...register('inicioVigenciaApolice', {
                      validate: (value) =>
                        value.match(/_/g) && value.match(/_/g).length < 0,
                    })}
                    onKeyUp={(fieldValue) =>
                      fieldValue.target.value.search('_') < 0
                        ? regrasVigencia(fieldValue.target.value)
                        : setIsValid(false)
                    }
                    className="input large"
                    mask={fieldMasks.date}
                  />
                  {helptext && <p className="helpText"> {helptext} </p>}
                  {errors.inicioVigenciaApolice && (
                    <p className="error">
                      {' '}
                      Campo obrigatório
                      <img
                        alt="Erro"
                        className="iconError"
                        src={ExclamationIcon}
                      />
                    </p>
                  )}
                </fieldset>
              </div>
              {/* <div className={`${block}--item`}>
              <fieldset className="fieldset" disabled>
              <label className="label large" htmlFor="fimVigencia">
              Fim da vigência
              </label>
              <img
              alt="Data"
              src={iconLock}
              className={`${block}--iconInput`}
              />
              <input
              defaultValue={
                dateEndVigencia
                ? dateEndVigencia
                : moment(data.informacoesSeguro.fimVigenciaApolice).format('DD/MM/YYYY')
              }
              placeholder="Será calculado 365 dias"
              {...register('fimVigencia')}
              className="input large"
              disabled
              />
              </fieldset>
            </div> */}
            </div>
          </div>
          <div className={`container--large`}>
            {isEditValid(quotation) && (
              <div className="action_buttons">
                <Button
                  className="button_cta"
                  type="button"
                  theme={'secondary'}
                  onClick={cancel}
                  disabled={disableSave}
                >
                  Cancelar edição
                </Button>
                <Button
                  className={`button_cta button--margin-left`}
                  type="submit"
                  theme={'tertiary'}
                  disabled={disableSave || !isValid}
                >
                  Salvar edição
                </Button>
              </div>
            )}
            {!quotation?.propostaId && (
              <Button type="submit" className="button_cta" disabled={!isValid}>
                Avançar
              </Button>
            )}
          </div>
        </form>
        <MainFooter />
      </main>
    </DefaultLayout>
  );
};

export default Page;
